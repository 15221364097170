/* :root {
  --color-gray-400: theme("colors.gray.400");
} */

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Change input caret color */
/* [type="text"].darkgray,
[type="email"].darkgray,
[type="url"].darkgray,
[type="password"].darkgray,
[type="number"].darkgray,
[type="date"].darkgray,
[type="datetime-local"].darkgray,
[type="month"].darkgray,
[type="search"].darkgray,
[type="tel"].darkgray,
[type="time"].darkgray,
[type="week"].darkgray,
[multiple].darkgray,
textarea.darkgray,
select.darkgray {
  caret-color: var(--color-gray-400);
} */

input[type="color" i] {
  min-height: 1.5rem;
  cursor: default;
  box-sizing: border-box;
  background-color: -internal-light-dark(rgb(239, 239, 239), rgb(59, 59, 59));
  color: -internal-light-dark(black, white);
  border-width: 1px;
  border-style: solid;
  border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
  border-image: initial;
  padding: 10px 2px 1px 2px ;
  height: 50px;
}

/* Odometer styles */
.odometer.odometer-auto-theme,
.odometer.odometer-theme-default {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative;
}
.odometer.odometer-auto-theme .odometer-digit,
.odometer.odometer-theme-default .odometer-digit {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer,
.odometer.odometer-theme-default .odometer-digit .odometer-digit-spacer {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  visibility: hidden;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner,
.odometer.odometer-theme-default .odometer-digit .odometer-digit-inner {
  text-align: left;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon,
.odometer.odometer-theme-default .odometer-digit .odometer-ribbon {
  display: block;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon-inner,
.odometer.odometer-theme-default .odometer-digit .odometer-ribbon-inner {
  display: block;
  -webkit-backface-visibility: hidden;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-value,
.odometer.odometer-theme-default .odometer-digit .odometer-value {
  display: block;
  -webkit-transform: translateZ(0);
}
.odometer.odometer-auto-theme
  .odometer-digit
  .odometer-value.odometer-last-value,
.odometer.odometer-theme-default
  .odometer-digit
  .odometer-value.odometer-last-value {
  position: absolute;
}
.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner,
.odometer.odometer-theme-default.odometer-animating-up .odometer-ribbon-inner {
  -webkit-transition: -webkit-transform 2s;
  -moz-transition: -moz-transform 2s;
  -ms-transition: -ms-transform 2s;
  -o-transition: -o-transform 2s;
  transition: transform 2s;
}
.odometer.odometer-auto-theme.odometer-animating-up.odometer-animating
  .odometer-ribbon-inner,
.odometer.odometer-theme-default.odometer-animating-up.odometer-animating
  .odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
}
.odometer.odometer-auto-theme.odometer-animating-down .odometer-ribbon-inner,
.odometer.odometer-theme-default.odometer-animating-down
  .odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
}
.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating
  .odometer-ribbon-inner,
.odometer.odometer-theme-default.odometer-animating-down.odometer-animating
  .odometer-ribbon-inner {
  -webkit-transition: -webkit-transform 2s;
  -moz-transition: -moz-transform 2s;
  -ms-transition: -ms-transform 2s;
  -o-transition: -o-transform 2s;
  transition: transform 2s;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.odometer.odometer-auto-theme,
.odometer.odometer-theme-default {
  font-family: "Helvetica Neue", sans-serif;
  line-height: 1.1em;
}
.odometer.odometer-auto-theme .odometer-value,
.odometer.odometer-theme-default .odometer-value {
  text-align: center;
}

/* Overrides */
.odometer {
  display: inline-block !important;
}

/* Popover */
.popover .arrow,
.popover .arrow::before {
  width: 10px;
  height: 10px;
}
.popover[data-popper-placement^="bottom"] > .arrow {
  top: -9px;
}
.popover[data-popper-placement^="top"] > .arrow {
  bottom: -9px;
}
.popover[data-popper-placement^="left"] > .arrow {
  right: -9px;
}
.popover[data-popper-placement^="right"] > .arrow {
  left: -9px;
}
.popover[data-popper-placement^="top"] > .arrow > svg {
  transform: rotate(180deg);
}
.popover[data-popper-placement^="left"] > .arrow > svg {
  transform: rotate(-90deg);
}
.popover[data-popper-placement^="right"] > .arrow > svg {
  transform: rotate(90deg);
}

/* Print */

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }

  img {
    page-break-before: auto;
    page-break-after: auto;
    page-break-inside: avoid;
    break-inside: avoid;
    display: block;
  }

  table {
    page-break-inside: auto;
    clear: both;
  }

  table th {
    page-break-inside: avoid;
  }

  table tr {
    page-break-inside: avoid;
    page-break-before: auto;
  }

  table td {
    page-break-inside: avoid;
  }

  table[data-split-from] {
    border-top: initial;
  }
  
  table[data-split-to] {
    border-bottom: initial;
  }

  .page-break-inside-avoid {
    page-break-inside: avoid;
    break-inside: avoid;
  }

  .page-break {
    page-break-inside: avoid;
    page-break-before: auto;
    break-inside: avoid;
    break-before: auto;
  }

  .page-break-always {    
    page-break-inside: avoid;
    page-break-before: always;
    break-before: always;
  }
}

@page {
  size: A4;
  /* margin: 15mm 10mm 15mm 10mm; */
  background-color: white;
}


/* Chrome, Safari, newer Opera */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Internet Explorer and Edge */
input[type="number"]::-ms-clear,
input[type="number"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
